import React, {useEffect} from "react";
import {navigate} from "gatsby";
import {PageLayout, LoadingSpinner} from "../components/components";

//
// This page will redirect to the dashboard
//
const RedirectToDashboardPage = () => {
  useEffect(() => {
    navigate("/clue-master/games");
  }, []);

  return (
    <PageLayout>
      <LoadingSpinner isLoading={true}/>
    </PageLayout>
  );
};

export default RedirectToDashboardPage;
